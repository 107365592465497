import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Menu, MenuItem, IconButton, Typography,
  Modal, Box, TextField, Grid, Select, MenuItem as MuiMenuItem, InputLabel, FormControl
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import style from './assets/css/admin.module.css';
import Swal from 'sweetalert2';

const Reviews = () => {
  const [users, setUsers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [newUser, setNewUser] = useState({
    username: '',
    email: '',
    password: '',
    role: '',
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/backend/api/users`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        Swal.fire('Error', 'Error fetching data', 'error');
      });
  };

  const handleActionClick = (event, user) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedUser(null);
  };

  const handleDelete = (userId) => {
    console.log('Delete user:', userId);
    handleCloseMenu();
  };

  const handleEdit = (userId) => {
    console.log('Edit user:', userId);
    handleCloseMenu();
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleCreateUser = () => {
    console.log('New user details:', newUser);
    // Perform user creation logic with newUser object
    // axios.post('/api/users', newUser) // Uncomment and modify the API call accordingly
    handleCloseModal();
  };

  return (
    <Paper sx={{ p: 3 }} className={style.bg_light}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
             <h3>Reviews</h3>
          </Grid>
        </Grid>
      <TableContainer component={Paper} sx={{ mt: 4, p: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="user table">
          <TableHead>
            <TableRow>
              <TableCell>Review ID</TableCell>
              <TableCell>Recipe</TableCell>
              <TableCell>Email Address</TableCell>
              <TableCell>Rating</TableCell>
              <TableCell>Comment</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={(event) => handleActionClick(event, user)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && selectedUser?.id === user.id}
                    onClose={handleCloseMenu}
                  >
                    <MenuItem onClick={() => handleEdit(user.id)}>Edit</MenuItem>
                    <MenuItem onClick={() => handleDelete(user.id)}>Delete</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </Paper>
  );
};

export default Reviews;
