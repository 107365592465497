import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Grid, TextField, Button, Typography, Radio, RadioGroup, FormControlLabel, FormLabel, Card, CardContent } from '@mui/material';
import style from '../../assets/css/admin.module.css';

const Settings_SMTP = () => {
    const [smtpSettings, setSmtpSettings] = useState({
        smtp_host: '',
        smtp_username: '',
        smtp_password: '',
        smtp_sender_name: '',
        smtp_sender_email: '',
        smtp_encryption: 'none',
        smtp_port: '',
    });

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/backend/api/setting`)
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setSmtpSettings(data.data);
            }
        })
        .catch(error => console.error('Error fetching settings:', error));
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSmtpSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        
        Object.keys(smtpSettings).forEach((key) => {
            formData.append(key, smtpSettings[key]);
        });

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/backend/api/setting`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            for (let pair of formData.entries()) {
                console.log(pair[0], pair[1]);
            }
            
            if (response.data.success) {
                alert('Settings updated successfully');
            } else {
                alert('Failed to update settings: ' + response.data.message);
            }
        } catch (error) {
            console.error('Error updating settings:', error);
            alert('Error updating settings: ' + error.message);
        }
    };

    return (
        <Box sx={{ padding: 3, backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
            <Card sx={{ boxShadow: 3 }}>
                <CardContent>
                    <Typography variant="h4" gutterBottom>
                        SMTP Settings
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="SMTP Host"
                                    name="smtp_host"
                                    variant="outlined"
                                    value={smtpSettings.smtp_host}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    label="SMTP Username"
                                    name="smtp_username"
                                    variant="outlined"
                                    value={smtpSettings.smtp_username}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    label="SMTP Password"
                                    name="smtp_password"
                                    type="password"
                                    variant="outlined"
                                    value={smtpSettings.smtp_password}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="From Name"
                                    name="smtp_sender_name"
                                    variant="outlined"
                                    value={smtpSettings.smtp_sender_name}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    label="From Email"
                                    name="smtp_sender_email"
                                    variant="outlined"
                                    value={smtpSettings.smtp_sender_email}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    label="SMTP Port"
                                    name="smtp_port"
                                    type="number"
                                    variant="outlined"
                                    value={smtpSettings.smtp_port}
                                    onChange={handleChange}
                                    margin="normal"
                                />

                                {/* Encryption Radio Buttons */}
                                <FormLabel component="legend" sx={{ mt: 3 }}>Encryption</FormLabel>
                                <RadioGroup
                                    name="smtp_encryption"
                                    value={smtpSettings.smtp_encryption}
                                    onChange={handleChange}
                                    row
                                >
                                    <FormControlLabel value="none" control={<Radio />} label="None" />
                                    <FormControlLabel value="ssl" control={<Radio />} label="SSL" />
                                    <FormControlLabel value="tls" control={<Radio />} label="TLS" />
                                </RadioGroup>
                            </Grid>
                        </Grid>

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 4, ':hover': { boxShadow: 4 } }}
                        >
                            Save Changes
                        </Button>
                    </form>
                </CardContent>
            </Card>
        </Box>
    );
}

export default Settings_SMTP;
