import React from 'react';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import UpdateSliderForm from './components/updateSliderForm';

const Update_Slider = () => {
  const { id } = useParams();

  return (
    <div className="mt-4 mx-auto">
      <UpdateSliderForm sliderId={id} />
    </div>
  );
};

export default Update_Slider;
