import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Typography,
  Divider,
  Grid,
  IconButton,
  Tabs,
  Tab,
  Paper,
} from '@mui/material';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Swal from 'sweetalert2';
import style from '../assets/css/admin.module.css';

const recipe_types = ['Breakfast', 'Brunch', 'Lunch', 'Dinner', 'Snacks', 'Desserts'];
const seasons = ['All Season', 'Pre-Winter', 'Winter', 'Summer', 'Spring', 'Autumn', 'Monsoon'];
const cusine = ['Not Specified', 'Italian', 'Mexican', 'Chinese', 'Indian', 'Mediterranean', 'Thai'];
const RecipeUpdateForm = ({ recipeId }) => {
  const [recipeDetails, setRecipeDetails] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [currentImage, setCurrentImage] = useState(null);
  const [formData, setFormData] = useState({
    category_id: '',
    title: '',
    permalink: '',
    difficulty_level: '',
    cooking_time: '',
    preparation_time: '',
    recipe_type: '',
    season: '',
    cusine: '',
    event_id: '',
    serving_size: '',
    serving_type: '',
    publish_status: 1,
    description: '',
    ingredients: '',
    directions: '',
    other_details: '',
    meta_description: '',
    meta_keyword: '',
    image: null,
  });
  const [categories, setCategories] = useState([]);
  const [events, setEvents] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchDetails();
  }, [recipeId]);

  useEffect(() => {
    fetchCategories();
    fetchEvents();
  }, []);

  const TabPanel = ({ children, value, index, ...other }) => {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const fetchCategories = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/backend/api/categories`)
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        Swal.fire('Error', 'Error fetching data', 'error');
      });
  };

  const fetchEvents = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/backend/api/events`)
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        Swal.fire('Error', 'Error fetching data', 'error');
      });
  };

  const handleCKEditorChange = (field) => (event, editor) => {
    const data = editor.getData();
    setFormData({ ...formData, [field]: data });
  };


  const fetchDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/backend/api/recipes/getOneById/${recipeId}`);
      console.log(response);
      setRecipeDetails(response.data);
      setFormData({
        category_id: response.data.category_id || '',
        title: response.data.title || '',
        permalink: response.data.permalink || '',
        difficulty_level: response.data.difficulty_level || '',
        cooking_time: response.data.cooking_time || '',
        preparation_time: response.data.preparation_time || '',
        recipe_type: response.data.recipe_type || '',
        season: response.data.season || '',
        cusine: response.data.cusine || '',
        event_id: response.data.event_id || '',
        serving_size: response.data.serving_size || '',
        serving_type: response.data.serving_type || '',
        publish_status: response.data.publish_status || '',
        description: response.data.description || '',
        ingredients: response.data.ingredients || '',
        directions: response.data.directions || '',
        other_details: response.data.other_details || '',
        meta_description: response.data.meta_description || '',
        meta_keyword: response.data.meta_keyword || '',
      });
      setCurrentImage(response.data.featured_image ? `${process.env.REACT_APP_IMAGE_PATH_URL}/${response.data.featured_image}` : null); // Set current image from db
    } catch (error) {
      Swal.fire('Error', 'Error fetching data', 'error');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0]; 
    setFormData({
      ...formData,
      image: file,
    });
    setCurrentImage(URL.createObjectURL(file)); // Preview new image
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setErrors({});
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("category_id", formData.category_id);
      formDataToSubmit.append("title", formData.title);
      formDataToSubmit.append("permalink", formData.permalink);
      formDataToSubmit.append("difficulty_level", formData.difficulty_level);
      formDataToSubmit.append("cooking_time", formData.cooking_time);
      formDataToSubmit.append("preparation_time", formData.preparation_time);
      formDataToSubmit.append("serving_size", formData.serving_size);
      formDataToSubmit.append("serving_type", formData.serving_type);
      formDataToSubmit.append("recipe_type", formData.recipe_type);
      formDataToSubmit.append("season", formData.season);
      formDataToSubmit.append("cusine", formData.cusine);
      formDataToSubmit.append("event_id", formData.event_id);
      formDataToSubmit.append("description", formData.description);
      formDataToSubmit.append("ingredients", formData.ingredients);
      formDataToSubmit.append("directions", formData.directions);
      formDataToSubmit.append("other_details", formData.other_details);
      formDataToSubmit.append("publish_status", formData.publish_status);
      formDataToSubmit.append("video", formData.video);
      formDataToSubmit.append("meta_description", formData.meta_description);
      formDataToSubmit.append("meta_keyword", formData.meta_keyword);
      if (formData.image) {
        formDataToSubmit.append("image", formData.image);
      } else {
        setErrors({ ...errors, image: "Image is required" });
        return;
      }

      const response = await axios.put(`${process.env.REACT_APP_API_URL}/backend/api/recipes/${recipeId}`, formDataToSubmit, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      await Swal.fire({
        title: 'Success!',
        text: 'Recipe details updated successfully.',
        icon: 'success',
        confirmButtonText: 'OK'
      });
      fetchDetails();
      setFormData({
        ...formData,
        title: '',
        permalink: '',
        description: '',
        ingredients: '',
        directions: '',
        meta_description: '',
        meta_keyword: '',
        image: null,
      });
      setErrors({});
    } catch (error) {
      if (error.response && error.response.data.errors) {
        const fieldErrors = {};
        error.response.data.errors.forEach(err => {
          fieldErrors[err.field] = err.message;
        });
        setErrors(fieldErrors);
      } else {
        await Swal.fire({
          title: 'Error!',
          text: 'Error updating the recipe!',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    }
  };

  return (
    <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 900,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
    
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Update Info
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <p>Update</p>
        </Grid>
      </Grid>

        <Tabs value={activeTab} onChange={handleTabChange} aria-label="Media Tabs">
            <Tab label="Basic Details" key={0} />
            <Tab label="Other Details" key={1} />
            <Tab label="Seo" key={1} />
            <Tab label="Featured Image" key={2} />
        </Tabs>

        <TabPanel value={activeTab} index={0} key={0}>
          <Grid container spacing={2}>
            
            <Grid item xs={12} md={4}>
              <TextField
                select
                label="Category"
                name="category_id"
                value={formData.category_id}
                onChange={handleChange}
                fullWidth
                error={!!errors.category_id}
                helperText={errors.category_id}
              >
                {categories.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.title}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* Title Input Field */}
            <Grid item xs={12} md={8}>
              <TextField
                label="Title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                fullWidth
                className={style.bg_white}
                error={!!errors.title}
                helperText={errors.title}
              />
            </Grid>

            {/* Permalink Input Field */}
            <Grid item xs={12} md={6}>
              <TextField
                label="Permalink"
                name="permalink"
                value={formData.permalink}
                onChange={handleChange}
                fullWidth
                className={style.bg_white}
                error={!!errors.permalink}
                helperText={errors.permalink}
              />
            </Grid>

            {/* Difficulty Level Radios */}
            <Grid item xs={12} md={6}>
              <FormControl>
                <FormLabel>Difficulty Level</FormLabel>
                <RadioGroup
                  row
                  name="difficulty_level"
                  value={formData.difficulty_level}
                  onChange={handleChange}
                  fullWidth
                  className={style.bg_white}
                  error={!!errors.difficulty_level}
                  helperText={errors.difficulty_level}
                >
                  <FormControlLabel value="easy" control={<Radio />} label="Easy" />
                  <FormControlLabel value="medium" control={<Radio />} label="Medium" />
                  <FormControlLabel value="hard" control={<Radio />} label="Hard" />
                </RadioGroup>
                {errors.difficulty_level && (
                  <Typography color="error" variant="body2">
                    {errors.difficulty_level}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            {/* Cook Time and Prepare Time Inputs (Inline) */}
            <Grid item xs={12} md={4}>
              <TextField
                label="Cook Time (minutes)"
                name="cooking_time"
                value={formData.cooking_time}
                onChange={handleChange}
                type="number"
                fullWidth
                className={style.bg_white}
                error={!!errors.cooking_time}
                helperText={errors.cooking_time}
                
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                label="Prepare Time (minutes)"
                name="preparation_time"
                value={formData.preparation_time}
                onChange={handleChange}
                type="number"
                fullWidth
                className={style.bg_white}
                error={!!errors.preparation_time}
                helperText={errors.preparation_time}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                label="Serving"
                name="serving_size"
                value={formData.serving_size}
                onChange={handleChange}
                type="number"
                fullWidth
                className={style.bg_white}
                error={!!errors.serving_size}
                helperText={errors.serving_size}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                label="Serving Type"
                name="serving_type"
                value={formData.serving_type}
                onChange={handleChange}
                type="text"
                fullWidth
                className={style.bg_white}
                error={!!errors.serving_type}
                helperText={errors.serving_type}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                  select
                  label="Meal"
                  name="recipe_type"
                  value={formData.recipe_type}
                  onChange={handleChange}
                  fullWidth
                  className={style.bg_white}
                  error={!!errors.recipe_type}
                  helperText={errors.recipe_type}
                >
                  {recipe_types.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                  select
                  label="Season"
                  name="season"
                  value={formData.season}
                  onChange={handleChange}
                  fullWidth
                  className={style.bg_white}
                  error={!!errors.season}
                  helperText={errors.season}
                >
                  {seasons.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                  select
                  label="Cusine"
                  name="cusine"
                  value={formData.cusine}
                  onChange={handleChange}
                  fullWidth
                  className={style.bg_white}
                  error={!!errors.cusine}
                  helperText={errors.cusine}
                >
                  {cusine.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                  select
                  label="Event"
                  name="event_id"
                  value={formData.event_id}
                  onChange={handleChange}
                  fullWidth
                  className={style.bg_white}
                  error={!!errors.event_id}
                  helperText={errors.event_id}
                >
                  {events.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.title}
                    </MenuItem>
                  ))}
                </TextField>
            </Grid>

            

          </Grid>
        </TabPanel>
        <TabPanel value={activeTab} index={1} key={1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>Description</FormLabel>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.description}
                  onChange={handleCKEditorChange('description')}
                  config={{
                    toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
                  }}
                />
                {errors.description && (
                  <Typography color="error" variant="body2">
                    {errors.description}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>Ingredients</FormLabel>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.ingredients}
                  onChange={handleCKEditorChange('ingredients')}
                  config={{
                    toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
                  }}
                />
                {errors.ingredients && (
                  <Typography color="error" variant="body2">
                    {errors.ingredients}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>Directions</FormLabel>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.directions}
                  onChange={handleCKEditorChange('directions')}
                  config={{
                    toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
                  }}
                />
                {errors.directions && (
                  <Typography color="error" variant="body2">
                    {errors.directions}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>Other Details</FormLabel>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.other_details}
                  onChange={handleCKEditorChange('other_details')}
                  config={{
                    toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
                  }}
                />
                {errors.other_details && (
                  <Typography color="error" variant="body2">
                    {errors.other_details}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={activeTab} index={2} key={2}>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                  select
                  label="Status"
                  name="publish_status"
                  value={formData.publish_status}
                  onChange={handleChange}
                  fullWidth
                  className={style.bg_white}
                  error={!!errors.publish_status}
                  helperText={errors.publish_status}
                >
                  <MenuItem key={1} value={1}>
                    Published
                  </MenuItem>
                  <MenuItem key={0} value={0}>
                    Draft
                  </MenuItem>
              </TextField>
            </Grid>


            <Grid item xs={12} md={6}>
              <TextField
                label="Video URL"
                name="video"
                value={formData.video}
                onChange={handleChange}
                fullWidth
                className={style.bg_white}
                error={!!errors.video}
                helperText={errors.video}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Meta Description"
                name="meta_description"
                value={formData.meta_description}
                onChange={handleChange}
                fullWidth
                multiline
                rows={4} 
                variant="outlined" 
                className={style.bg_white}
                error={!!errors.meta_description}
                helperText={errors.meta_description}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Meta Keyword"
                name="meta_keyword"
                value={formData.meta_keyword}
                onChange={handleChange}
                fullWidth
                multiline
                rows={4} 
                variant="outlined" 
                className={style.bg_white}
                error={!!errors.meta_keyword}
                helperText={errors.meta_keyword}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={activeTab} index={3} key={3}>
        <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel>Upload Media File</FormLabel>
              {currentImage && (
                <img src={currentImage} alt="Current Category" style={{ width: '100%', marginBottom: '10px', maxHeight: '200px', objectFit: 'contain' }} />
              )}
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: 'block', marginBottom: '10px' }}
              />
              {errors.featured_image && (
                <Typography color="error" variant="body2">
                  {errors.featured_image}
                </Typography>
              )}
            </FormControl>
          </Grid>
        </TabPanel>
      </form>
    </Box>
  );
};

export default RecipeUpdateForm;
