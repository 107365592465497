import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Menu, MenuItem, IconButton, Typography,
  Modal, Box, TextField, Grid, Select, MenuItem as MuiMenuItem, InputLabel, FormControl, InputAdornment, IconButton as MuiIconButton
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import style from './assets/css/admin.module.css';
import Swal from 'sweetalert2';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setformData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '', 
    role_id: '',
  });

  useEffect(() => {
    fetchUsers();
    fetchRoles();
  }, []);

  const fetchRoles = () => {
    axios.get(`${process.env.PUBLIC_URL}/json/role.json`)
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
        Swal.fire('Error', 'Error fetching roles', 'error');
      });
  };
  

  const fetchUsers = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/backend/api/users`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        Swal.fire('Error', 'Error fetching data', 'error');
      });
  };

  const handleActionClick = (event, user) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedUser(null);
  };

  const handleDelete = (userId) => {
    console.log('Delete user:', userId);
    handleCloseMenu();
  };

  const handleEdit = (userId) => {
    console.log('Edit user:', userId);
    handleCloseMenu();
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setformData({ ...formData, [name]: value });
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    try {
      setErrors({});
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("full_name", formData.full_name);
      formDataToSubmit.append("email", formData.email);
      formDataToSubmit.append("password", formData.password);
      formDataToSubmit.append("password", formData.confirmPassword);
      formDataToSubmit.append("role_id", formData.role_id);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/backend/api/users`, formDataToSubmit );
      await Swal.fire({
          title: 'Success!',
          text: 'Category created successfully.',
          icon: 'success',
          confirmButtonText: 'OK'
      });
      setformData({
        full_name: '',
        email: '',
        password: '',
        confirmPassword: '',
        role_id: '',
    });
    setErrors({});
    handleCloseModal();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const fieldErrors = {};
        error.response.data.errors.forEach(err => {
          fieldErrors[err.field] = err.message;
        });
        setErrors(fieldErrors);
      }else{
        await Swal.fire({
            title: 'Error!',
            text: 'Error creating a new user!',
            icon: 'error',
            confirmButtonText: 'OK'
        });
      }
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <Paper sx={{ p: 3 }} className={style.bg_light}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
             <h3>Manage Users</h3>
          </Grid>
          <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                sx={{ mb: 2 }}
                onClick={handleOpenModal}
              >
                Create New User
            </Button>
          </Grid>
        </Grid>
      <TableContainer component={Paper} sx={{ mt: 4, p: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="user table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.full_name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role_id}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={(event) => handleActionClick(event, user)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && selectedUser?.id === user.id}
                    onClose={handleCloseMenu}
                  >
                    <MenuItem onClick={() => handleEdit(user.id)}>Edit</MenuItem>
                    <MenuItem onClick={() => handleDelete(user.id)}>Delete</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for creating a new user */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="create-user-modal"
        aria-describedby="create-user-form"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Create New User
          </Typography>
          <TextField
            label="Full Name"
            name="full_name"
            fullWidth
            variant="outlined"
            margin="normal"
            value={formData.full_name}
            error={!!errors.full_name}
            helperText={errors.full_name}
            onChange={handleInputChange}
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            fullWidth
            variant="outlined"
            margin="normal"
            value={formData.email}
            error={!!errors.email}
            helperText={errors.email}
            onChange={handleInputChange}
          />
          <TextField
            label="Password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            variant="outlined"
            margin="normal"
            value={formData.password}
            error={!!errors.password}
            helperText={errors.password}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <MuiIconButton onClick={togglePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </MuiIconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirm Password"
            name="confirmPassword"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            variant="outlined"
            margin="normal"
            value={formData.confirmPassword}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <MuiIconButton onClick={togglePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </MuiIconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="role-label">Role</InputLabel>
            <Select
              labelId="role-label"
              name="role_id"
              value={formData.role_id}
              onChange={handleInputChange}
              label="Role"
              error={!!errors.role_id}
              helperText={errors.role_id}
            >
              {roles.map((role) => (
                <MenuItem key={role.role_id} value={role.role_id}>
                  {role.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleCreateUser}
          >
            Create User
          </Button>
        </Box>
      </Modal>
    </Paper>
  );
};

export default Users;
