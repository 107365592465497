import React from 'react';

const IconDisplay = ({ icon }) => {
  return (
    <div style={{ fontSize: '24px', textAlign: 'center', margin: '10px' }}>
      {icon}
    </div>
  );
};

export default IconDisplay;
