import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import style from './assets/css/admin.module.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/admin'); 
    }
  }, [navigate]);

  const handleLogin = async () => {
    try {
      setError('');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/backend/api/login`, { email, password });
      localStorage.setItem('token', response.data.token);
      navigate('/admin');
    } catch (error) {
      if (error.response && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    }
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <div className={style.login}>
        <div className='col-md-4 col-sm-10 xol-xs-10 p-4 rounded bg-light position-absolute top-50 start-50 translate-middle'>
         <h3 className='h3 fw-bold'>Admin Login</h3><hr></hr>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <form onSubmit={handleSubmit}>
                <div>
                <label>Username</label>
                <input
                    type="text"
                    value={email}
                    className='form-control shadow-none'
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                </div>
                <div>
                <label>Password</label>
                <input
                    type="password"
                    value={password}
                    className='form-control shadow-none'
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                </div>
                <div align="center">
                   <button type="submit" className='btn btn-primary my-2'>Login</button>
                </div>
            </form>
        </div>
      
    </div>
  );
};

export default Login;
