import React, { useState, useEffect } from 'react';
import {
    AppBar,
    Tabs,
    Tab,
    Box,
    Typography,
    styled,
} from '@mui/material';
import axios from 'axios';
import Settings_General from './components/tabs/settings-general';
import Settings_SMTP from './components/tabs/settings-smtp';
import Settings_SocialMedia from './components/tabs/setting-socialmedia';
import Settings_GoogleAnalytics from './components/tabs/settings-googleanalytics';
import Settings_SEO from './components/tabs/settings-seo';

// Styled Tab Component
const StyledTab = styled(Tab)(({ theme }) => ({
    '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main, // Change background color for active tab
        color: theme.palette.common.white, // Change text color for active tab
    },
    '&:hover': {
        backgroundColor: theme.palette.primary.light, // Change background on hover
    },
}));

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tab-panel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const Settings = () => {
    const [value, setValue] = useState(0);
    const [settings, setSettings] = useState({
        site_title: '',
        tagline: '',
        description: '',
        date_format: '',
        time_format: ''
    });

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/backend/api/setting`)
            .then(response => {
                if (response.data.length > 0) {
                    setSettings(response.data[0]);
                }
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
            });
    }, []);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSettings(prevSettings => ({
            ...prevSettings,
            [name]: value
        }));
    };

    return (
        <div>
            <AppBar position="static">
                <Tabs value={value} onChange={handleTabChange} aria-label="settings tabs">
                    <StyledTab label="General Settings" />
                    <StyledTab label="SMTP Settings" />
                    <StyledTab label="Social Media" />
                    <StyledTab label="SEO" />
                    <StyledTab label="Google Analytics Api" />
                </Tabs>
            </AppBar>

            <TabPanel value={value} index={0}>
                <Settings_General />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Settings_SMTP />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Settings_SocialMedia />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Settings_SEO />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <Settings_GoogleAnalytics />
            </TabPanel>
        </div>
    );
}

export default Settings;
