import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  FormControl,
  FormLabel,
  Typography,
  Divider,
  MenuItem,
  Grid,
} from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2'; 
import style from '../assets/css/admin.module.css';

const UpdateEventForm = ({ sliderId }) => {
  const [recipes, setRecipes] = useState([]);
  const [formData, setFormData] = useState({
    recipe_id: '',
    title: '',
    desktop_image: null,
    tablet_image: null,
    mobile_image: null,
  });
  const [errors, setErrors] = useState({});
  const [sliderDetails, setSliderDetails] = useState(null);
  const [desktopImage, setDesktopImage] = useState(null); 
  const [tabletImage, setTabletImage] = useState(null); 
  const [mobileImage, setMobileImage] = useState(null); 

  useEffect(() => {
    fetchDetails();
    recipesFetch();
  }, [sliderId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e, imageType) => {
    const file = e.target.files[0]; 
    setFormData({
      ...formData,
      [imageType]: file,
    });

    // Set preview for the specific image type
    if (imageType === 'desktop_image') setDesktopImage(URL.createObjectURL(file));
    else if (imageType === 'tablet_image') setTabletImage(URL.createObjectURL(file));
    else if (imageType === 'mobile_image') setMobileImage(URL.createObjectURL(file));
  };

  const fetchDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/backend/api/sliders/getById/${sliderId}`);
      setSliderDetails(response.data);
      setFormData({
        title: response.data.title || '',
        recipe_id: response.data.recipe_id || '',
        desktop_image: null,
        tablet_image: null,
        mobile_image: null,
      });
      setDesktopImage(response.data.desktop_image ? `${process.env.REACT_APP_IMAGE_PATH_URL}/${response.data.desktop_image}` : null);
      setTabletImage(response.data.tablet_image ? `${process.env.REACT_APP_IMAGE_PATH_URL}/${response.data.tablet_image}` : null);
      setMobileImage(response.data.mobile_image ? `${process.env.REACT_APP_IMAGE_PATH_URL}/${response.data.mobile_image}` : null); 
    } catch (error) {
      Swal.fire('Error', 'Error fetching data', 'error');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setErrors({});
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("recipe_id", formData.recipe_id);
      formDataToSubmit.append("title", formData.title);
      if (formData.desktop_image) formDataToSubmit.append("desktop_image", formData.desktop_image);
      if (formData.tablet_image) formDataToSubmit.append("tablet_image", formData.tablet_image);
      if (formData.mobile_image) formDataToSubmit.append("mobile_image", formData.mobile_image);

      const response = await axios.put(`${process.env.REACT_APP_API_URL}/backend/api/sliders/${sliderId}`, formDataToSubmit, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      Swal.fire({
        title: 'Success!',
        text: 'Slider updated successfully.',
        icon: 'success',
        confirmButtonText: 'OK'
      });
      fetchDetails(); // Refresh the data after updating
    } catch (error) {
      if (error.response && error.response.data.errors) {
        const fieldErrors = {};
        error.response.data.errors.forEach(err => {
          fieldErrors[err.field] = err.message;
        });
        setErrors(fieldErrors);
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Error updating the slider!',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    }
  };

  const recipesFetch = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/backend/api/recipes`);
      setRecipes(response.data);
    } catch (error) {
      Swal.fire('Error', 'Error fetching recipes', 'error');
    }
  };

  return (
    <Box sx={{ maxWidth: '80%',  background:'white', mx: 'auto', mt: 4, p: 3, border: '1px solid #ccc', borderRadius: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Update Slider {sliderId}
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Link to="/admin/event">Back</Link>
        </Grid>
      </Grid>
      <Divider orientation="horizontal" variant="middle" flexItem />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel>Desktop Image</FormLabel>
              {desktopImage && <img src={desktopImage} alt="Desktop" style={{ width: '100%', marginBottom: '10px', maxHeight: '200px', objectFit: 'contain' }} />}
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange(e, 'desktop_image')}
                style={{ display: 'block', marginBottom: '10px' }}
              />
              {errors.desktop_image && <Typography color="error" variant="body2">{errors.desktop_image}</Typography>}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel>Tablet Image</FormLabel>
              {tabletImage && <img src={tabletImage} alt="Tablet" style={{ width: '100%', marginBottom: '10px', maxHeight: '200px', objectFit: 'contain' }} />}
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange(e, 'tablet_image')}
                style={{ display: 'block', marginBottom: '10px' }}
              />
              {errors.tablet_image && <Typography color="error" variant="body2">{errors.tablet_image}</Typography>}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel>Mobile Image</FormLabel>
              {mobileImage && <img src={mobileImage} alt="Mobile" style={{ width: '100%', marginBottom: '10px', maxHeight: '200px', objectFit: 'contain' }} />}
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange(e, 'mobile_image')}
                style={{ display: 'block', marginBottom: '10px' }}
              />
              {errors.mobile_image && <Typography color="error" variant="body2">{errors.mobile_image}</Typography>}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              select
              label="Recipe"
              name="recipe_id"
              value={formData.recipe_id}
              onChange={handleChange}
              error={!!errors.recipe_id}
              helperText={errors.recipe_id}
              fullWidth
            >
              {recipes.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              fullWidth
              className={style.bg_white}
              error={!!errors.title}
              helperText={errors.title}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default UpdateEventForm;
