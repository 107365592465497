import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  FormControl,
  FormLabel,
  Typography,
  Divider,
  Grid,
} from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2'; 
import style from '../assets/css/admin.module.css';

const EventForm = () => {
  const [formData, setFormData] = useState({
    title: '',
    permalink: '',
    description: '',
    meta_description: '',
    meta_keyword: '',
    image: null,
  });
  const [errors, setErrors] = useState({}); // Error state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      permalink: name === 'title' ? value.toLowerCase().replace(/\s+/g, '-') : formData.permalink,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0]; 
    setFormData({
      ...formData,
      image: file,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setErrors({});
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("title", formData.title);
      formDataToSubmit.append("permalink", formData.permalink);
      formDataToSubmit.append("description", formData.description);
      formDataToSubmit.append("meta_description", formData.meta_description);
      formDataToSubmit.append("meta_keyword", formData.meta_keyword);
      if (formData.image) {
        formDataToSubmit.append("image", formData.image);
      } else {
        setErrors({ ...errors, image: "Image is required" });
        return;
      }
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/backend/api/events`, formDataToSubmit, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      await Swal.fire({
          title: 'Success!',
          text: 'Event created successfully.',
          icon: 'success',
          confirmButtonText: 'OK'
      });
      setFormData({
        title: '',
        permalink: '',
        description: '',
        meta_description: '',
        meta_keyword: '',
        image: null,
    });
    setErrors({});
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const fieldErrors = {};
        error.response.data.errors.forEach(err => {
          fieldErrors[err.field] = err.message;
        });
        setErrors(fieldErrors);
      }else{
        await Swal.fire({
            title: 'Error!',
            text: 'Error creating a new event!',
            icon: 'error',
            confirmButtonText: 'OK'
        });
      }
    }
  };

  return (
    <Box sx={{ maxWidth: '80%', background:'white', mx: 'auto', mt: 4, p: 3, border: '1px solid #ccc', borderRadius: 2 }}>
      
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Create New Event
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Link to="/admin/event">Back</Link>
        </Grid>
      </Grid>
      <Divider orientation="horizontal" variant="middle" flexItem />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} sx={{mt: 2}}>

        <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel>Upload Media File</FormLabel>
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: 'block', marginBottom: '10px' }}
              />
              {errors.image && (
                <Typography color="error" variant="body2">
                  {errors.image}
                </Typography>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              fullWidth
              className={style.bg_white}
              error={!!errors.title}
              helperText={errors.title}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Permalink"
              name="permalink"
              value={formData.permalink}
              onChange={handleChange}
              fullWidth
              className={style.bg_white}
              error={!!errors.permalink}
              helperText={errors.permalink}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              multiline
              className={style.bg_white}
              rows={4}
              fullWidth
              error={!!errors.description}
              helperText={errors.description}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Meta Description"
              name="meta_description"
              value={formData.meta_description}
              onChange={handleChange}
              multiline
              className={style.bg_white}
              rows={4}
              fullWidth
              error={!!errors.meta_description}
              helperText={errors.meta_description}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Meta Keywords"
              name="meta_keyword"
              value={formData.meta_keyword}
              onChange={handleChange}
              multiline
              className={style.bg_white}
              rows={4}
              fullWidth
              error={!!errors.meta_keyword}
              helperText={errors.meta_keyword}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default EventForm;
