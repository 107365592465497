import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  FormControl,
  FormLabel,
  Typography,
  Divider,
  MenuItem,
  Grid,
} from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2'; 
import style from '../assets/css/admin.module.css';

const SliderForm = () => {
  const [recipes, setRecipes] = useState([]);
  const [formData, setFormData] = useState({
    recipe_id: '',
    title: '',
    desktop_image: null,
    tablet_image: null,
    mobile_image: null,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    recipesFetch();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e, field) => {
    const file = e.target.files[0]; 
    setFormData({
      ...formData,
      [field]: file,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setErrors({});
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("recipe_id", formData.recipe_id);
      formDataToSubmit.append("title", formData.title);
      formDataToSubmit.append("desktop_image", formData.desktop_image);
      formDataToSubmit.append("tablet_image", formData.tablet_image);
      formDataToSubmit.append("mobile_image", formData.mobile_image);

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/backend/api/sliders`, formDataToSubmit, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      await Swal.fire({
        title: 'Success!',
        text: 'Slider created successfully.',
        icon: 'success',
        confirmButtonText: 'OK'
      });
      setFormData({
        recipe_id: '',
        title: '',
        desktop_image: null,
        tablet_image: null,
        mobile_image: null,
      });
      setErrors({});
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const fieldErrors = {};
        error.response.data.errors.forEach(err => {
          fieldErrors[err.field] = err.message;
        });
        setErrors(fieldErrors);
      } else {
        await Swal.fire({
          title: 'Error!',
          text: 'Error creating a new slider!',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    }
  };

  const recipesFetch = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/backend/api/recipes`)
      .then((response) => {
        setRecipes(response.data);
      })
      .catch((error) => {
        Swal.fire('Error', 'Error fetching data', 'error');
      });
  };

  return (
    <Box sx={{ maxWidth: '80%', background:'white', mx: 'auto', mt: 4, p: 3, border: '1px solid #ccc', borderRadius: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Create New Slider
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Link to="/admin/slider">Back</Link>
        </Grid>
      </Grid>
      <Divider orientation="horizontal" variant="middle" flexItem />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} sx={{mt: 2}}>
          {/* Image Fields */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel>Desktop Image</FormLabel>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange(e, 'desktop_image')}
                style={{ display: 'block', marginBottom: '10px' }}
              />
              {errors.desktop_image && (
                <Typography color="error" variant="body2">
                  {errors.desktop_image}
                </Typography>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel>Tablet Image</FormLabel>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange(e, 'tablet_image')}
                style={{ display: 'block', marginBottom: '10px' }}
              />
              {errors.tablet_image && (
                <Typography color="error" variant="body2">
                  {errors.tablet_image}
                </Typography>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel>Mobile Image</FormLabel>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange(e, 'mobile_image')}
                style={{ display: 'block', marginBottom: '10px' }}
              />
              {errors.mobile_image && (
                <Typography color="error" variant="body2">
                  {errors.mobile_image}
                </Typography>
              )}
            </FormControl>
          </Grid>

          {/* Text Fields */}
          <Grid item xs={12}>
            <TextField
              select
              label="Recipe"
              name="recipe_id"
              value={formData.recipe_id}
              onChange={handleChange}
              error={!!errors.recipe_id}
              helperText={errors.recipe_id}
              fullWidth
            >
              {recipes.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              fullWidth
              className={style.bg_white}
              error={!!errors.title}
              helperText={errors.title}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default SliderForm;
