import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Divider,
  Tabs,
  Tab,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Collapse,
  Box,
  Popover,
  Modal,
  FormControl,
  InputLabel,
  TextField,
  Select,
  FormLabel,
  CircularProgress,
  Chip,
  Grid,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from 'axios';
import Swal from 'sweetalert2';
import CategoryIcon from '@mui/icons-material/Category';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import style from './assets/css/admin.module.css';
import RecipeUpdateForm from './components/recipeUpdateForm';

const ITEM_HEIGHT = 48;
const options = ['View', 'Edit', 'Delete'];


function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const Recipe = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [records, setRecords] = useState([]);
  const [progressData, setProgressData] = useState({});
  const [loadingProgress, setLoadingProgress] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openCollapse, setOpenCollapse] = useState({});
  const [popoverAnchor, setPopoverAnchor] = useState(null); 
  const [selectedImage, setSelectedImage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openNutritionModal, setOpenNutritionModal] = useState(false);
  const [openRecipeEditModal, setOpenRecipeEditModal] = useState(false);
  const [media, setMedia] = useState(null);
  const [recipeId, setRecipeId] = useState(null);
  const [nutritionType, setNutritionType] = useState('');
  const [nutritionValue, setNutritionValue] = useState('');
  const [nutritionUnit, setNutritionUnit] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchRecipes();
  }, []);

  const TabPanel = ({ children, value, index, ...other }) => {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  const fetchRecipes = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/backend/api/recipes`)
      .then((response) => {
        setRecords(response.data);
      })
      .catch((error) => {
        Swal.fire('Error', 'Error fetching data', 'error');
      });
  };

  useEffect(() => {
    if (records.length > 0) {
      setLoadingProgress(true); // Set loading to true when starting to fetch progress
      const fetchProgressPromises = records.map((row) => 
        axios.get(`${process.env.REACT_APP_API_URL}/backend/api/recipes/getRecipeProgress/${row.id}`)
          .then((response) => {
            setProgressData((prev) => ({
              ...prev,
              [row.id]: response.data.progress
            }));
          })
          .catch((error) => {
            Swal.fire('Error', `Error fetching progress for recipe ${row.id}`, 'error');
          })
      );

      // Wait for all progress fetches to complete
      Promise.all(fetchProgressPromises).finally(() => {
        setLoadingProgress(false); // Set loading to false once all fetches are done
      });
    }
  }, [records]);
  
  

  const deleteCategory = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this category?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${process.env.REACT_APP_API_URL}/backend/api/categories/${id}`)
          .then(() => {
            Swal.fire('Deleted!', 'Category has been deleted.', 'success');
            fetchRecipes();
          })
          .catch((error) => {
            Swal.fire('Error', 'Error deleting category', 'error');
          });
      }
    });
  };

  const deleteMedia = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this media file?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${process.env.REACT_APP_API_URL}/backend/api/media/${id}`)
          .then(() => {
            Swal.fire('Deleted!', 'Media has been deleted.', 'success');
            fetchRecipes();
          })
          .catch((error) => {
            Swal.fire('Error', 'Error deleting media', 'error');
          });
      }
    });
  };

  const deleteNutrition = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this entry?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${process.env.REACT_APP_API_URL}/backend/api/nutrition/${id}`)
          .then(() => {
            Swal.fire('Deleted!', 'Nutrition has been deleted.', 'success');
            fetchRecipes();
          })
          .catch((error) => {
            Swal.fire('Error', 'Error deleting nutrition', 'error');
          });
      }
    });
  };

  const handleMenuClick = (option, row) => {
    setAnchorEl(null);
    if (option === 'View') {
      Swal.fire('View Category', `Viewing category ID: ${row.id}`, 'info');
    } else if (option === 'Edit') {
      Swal.fire('Edit Category', `Editing category ID: ${row.id}`, 'info');
    } else if (option === 'Delete') {
      deleteCategory(row.id);
    }
  };

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleCollapse = (id) => {
    setOpenCollapse((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleImageClick = (event, imageUrl) => {
    setPopoverAnchor(event.currentTarget);
    setSelectedImage(imageUrl);
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleFileChange = (event) => {
    setMedia(event.target.files[0]);
  };

  const handleOpenModal = (id) => {
    setRecipeId(id); 
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setRecipeId(null); 
    setOpenModal(false);
  };

  const handleOpenRecipeEditModal = (id) => {
    setRecipeId(id); 
    setOpenRecipeEditModal(true);
    
  };
  const handleCloseRecipeEditModal = () => {
    setRecipeId(null); 
    setOpenRecipeEditModal(false);
  };

  const handleOpenNutritionModal = (id) => {
    setRecipeId(id); 
    setOpenNutritionModal(true);
  };
  
  const handleCloseNutritionModal = () => {
    setRecipeId(null); 
    setOpenNutritionModal(false);
  };

  const handleNutritionTypeChange = (event) => {
    setNutritionType(event.target.value);
  };
  
  const handleNutritionValueChange = (event) => {
    setNutritionValue(event.target.value);
  };
  const handleNutritionUnitChange = (event) => {
    setNutritionUnit(event.target.value);
  };

  const handleUploadMedia = async () => {
    if (!media || !recipeId) return;
    const formData = new FormData();
    formData.append('recipe_id', recipeId); 
    formData.append('file', media);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/backend/api/media`, {
        method: 'POST',
        body: formData,
      });
      fetchRecipes();
      handleCloseModal();
    } catch (error) {
      console.error('Error uploading file:', error);
    }
    handleCloseModal();
  };
  
  const handleNutritionAdd = async () => {
    if (!nutritionType || !nutritionValue || !nutritionUnit || !recipeId) {
      alert('Please select a nutrition type and enter a value.');
      return;
    }
    const nutritionData = {
      recipe_id: recipeId,
      title: nutritionType,   
      value: nutritionValue, 
      unit: nutritionUnit,
    };
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/backend/api/nutrition`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(nutritionData),
      });
        fetchRecipes();
        handleCloseNutritionModal(); 
        setNutritionType(''); 
        setNutritionValue('');
        setNutritionUnit('');
    } catch (error) {
      console.error('Error posting nutrition data:', error);
      alert('An error occurred while adding nutrition.');
    }
  };
  
  return (
    <Paper sx={{ p: 3 }} className={style.bg_light}>
      
      <Grid container spacing={2}>
        <Grid item xs={6}>
            <h3>Manage Recipes</h3>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Link to="create">Add New</Link>
        </Grid>
      </Grid>
      <Divider orientation="horizontal" variant="middle" flexItem />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Image</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Permalink</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Cusine</TableCell>
              <TableCell>Rating</TableCell>
              <TableCell>Progress</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <React.Fragment key={row.id}>
                <TableRow
                    style={{
                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white', // Alternating row colors
                    }}
                >
                    <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => toggleCollapse(row.id)}
                    >
                        {openCollapse[row.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    </TableCell>
                    <TableCell>
                    <img
                        src={`${process.env.REACT_APP_IMAGE_PATH_URL}/${row.featured_image}`}
                        alt={row.title}
                        style={{ maxWidth: '100%', maxHeight: '60px', cursor: 'pointer' }}
                        onClick={(event) => handleImageClick(event, `${process.env.REACT_APP_IMAGE_PATH_URL}/${row.featured_image}`)}
                    />
                    </TableCell>
                    <TableCell><b>{row.title}</b></TableCell>
                    <TableCell>
                      <Link to={row.permalink}>
                         {row.permalink}
                      </Link>
                    </TableCell>
                    <TableCell>
                        <Chip icon={<CategoryIcon />} label={row.category.title} />
                    </TableCell>
                    <TableCell>{row.cusine}</TableCell>
                    <TableCell>{row.average_rating} / {row.rating_count}</TableCell>
                    <TableCell>
                      {loadingProgress ? (
                        <Box display="flex" justifyContent="center" alignItems="center">
                          <CircularProgress size={24} />
                        </Box>
                      ) : (
                        <CircularProgressWithLabel
                          value={progressData[row.id] || 0}
                          size={40} 
                        />
                      )}
                    </TableCell>
                    <TableCell>
                    <IconButton
                        aria-label="more"
                        aria-controls={anchorEl ? 'long-menu' : undefined}
                        aria-haspopup="true"
                        onClick={(event) => handleClick(event, row)}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl && selectedRow === row)}
                        onClose={handleClose}
                        PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                        },
                        }}
                    >
                        {options.map((option) => (
                        <MenuItem
                            key={option}
                            onClick={() => handleMenuClick(option, row)}
                        >
                            {option}
                        </MenuItem>
                        ))}
                    </Menu>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={openCollapse[row.id]} timeout="auto" unmountOnExit>
                    <Box sx={{ maxWidth: '100%', mx: 'auto'}}>
                        <Tabs value={activeTab} onChange={handleTabChange} aria-label="Media Tabs">
                            <Tab label="Recipe Details" key={0} />
                            <Tab label="Media" key={1} />
                            <Tab label="Nutritions" key={1} />
                        </Tabs>
                          <TabPanel value={activeTab} index={0} key={0}>
                          <Grid container spacing={2}>
                             <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    sx={{ mb: 2 }}
                                    onClick={() => handleOpenRecipeEditModal(row.id)}
                                  >
                                    Update Details
                                </Button>
                              </Grid>
                            <Grid item xs={12} md={3}>
                              <Box margin={2}>
                                <h6><b>Recipe Type / Difficulty Level</b></h6>
                                <p>{row.recipe_type} / {row.difficulty_level}</p>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Box margin={2}>
                                <h6><b>Serving Type / Size</b></h6>
                                <p>{row.serving_type} / {row.serving_size}</p>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Box margin={2}>
                                <h6><b>Prep Time / Cook Time</b></h6>
                                <p>{row.preparation_time} / {row.cooking_time}</p>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Box margin={2}>
                                <h6><b>Event</b></h6>
                                <p>{row.event_id ? row.event.title : 'No event'}</p>
                              </Box>
                            </Grid>
                          </Grid>
                          <Divider orientation="horizontal" variant="middle" flexItem />
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={4}>
                                <Box margin={2}>
                                  <h6><b>Description</b></h6>
                                  <p dangerouslySetInnerHTML={{ __html: row.description }}></p>
                                </Box>
                                <Divider orientation="horizontal" variant="middle" flexItem />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Box margin={2}>
                                  <h6><b>Ingredient</b></h6>
                                  <p dangerouslySetInnerHTML={{ __html: row.ingredients }}></p>
                                </Box>
                                <Divider orientation="horizontal" variant="middle" flexItem />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Box margin={2}>
                                  <h6><b>Directions</b></h6>
                                  <p dangerouslySetInnerHTML={{ __html: row.directions }}></p>
                                </Box>
                                <Divider orientation="horizontal" variant="middle" flexItem />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Box margin={2}>
                                  <h6><b>Other Details</b></h6>
                                  <p dangerouslySetInnerHTML={{ __html: row.other_details }}></p>
                                </Box>
                                <Divider orientation="horizontal" variant="middle" flexItem />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Box margin={2}>
                                  <h6><b>Meta Description</b></h6>
                                  <p>{row.meta_description}</p>
                                </Box>
                                <Divider orientation="horizontal" variant="middle" flexItem />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Box margin={2}>
                                  <h6><b>Meta Keywords</b></h6>
                                  <p>{row.meta_keyword}</p>
                                </Box>
                                <Divider orientation="horizontal" variant="middle" flexItem />
                              </Grid>
                            </Grid>
                          </TabPanel>


                          <TabPanel value={activeTab} index={1} key={1}>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Button
                                  variant="contained"
                                  color="primary"
                                  startIcon={<AddIcon />}
                                  sx={{ mb: 2 }}
                                  onClick={() => handleOpenModal(row.id)}
                                >
                                  Upload Media
                              </Button>
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }}>
                              {row.recipe_media.map((mediaItem, index) => (
                                <Box key={index} sx={{ position: 'relative', m: 1 }}>
                                  <img
                                    src={`${process.env.REACT_APP_IMAGE_PATH_URL}/${mediaItem.fileurl}`}
                                    alt={row.title}
                                    style={{ maxWidth: '100%', maxHeight: '120px', cursor: 'pointer' }}
                                    onClick={(event) => handleImageClick(event, `${process.env.REACT_APP_IMAGE_PATH_URL}/${mediaItem.fileurl}`)}
                                  />
                                  <IconButton
                                    onClick={() => deleteMedia(mediaItem.id)}
                                    color="error"
                                    sx={{ position: 'absolute', top: 0, right: 0 }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              ))}
                            </Grid>
                          </TabPanel>


                          <TabPanel value={activeTab} index={2} key={2}>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    sx={{ mb: 2 }}
                                    onClick={() => handleOpenNutritionModal(row.id)}
                                  >
                                    Add Nutrition
                                </Button>
                              </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2} alignItems="center">
                                {row.nutrition.map((item, index) => (
                                  <Grid key={index} item container alignItems="center" spacing={2}>
                                    <Grid item xs={2}>
                                      <h6><b>{item.title}:</b></h6>
                                    </Grid>
                                    <Grid item xs={2}>
                                      {item.value} / {item.unit}
                                    </Grid>
                                    <Grid item xs={1}>
                                      <IconButton
                                        onClick={() => deleteNutrition(item.id)}
                                        color="error"
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Grid>
                                    <Divider orientation="horizontal" variant="middle" flexItem />
                                  </Grid>
                                ))}
                              </Grid>
                            </Grid>
                          </TabPanel>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <p><b>Published By: </b>{row.user.username}</p>
                            </Grid>
                            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <p>{row.createdAt} / {row.updatedAt}</p>
                            </Grid>
                          </Grid>
                      </Box>
                    </Collapse>
                    </TableCell>
                </TableRow>
                </React.Fragment>
            ))}
            </TableBody>

        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={records.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Popover
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <img
          src={selectedImage}
          alt="Enlarged category"
          style={{ maxWidth: '500px', maxHeight: '500px' }}
        />
      </Popover>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="create-user-modal"
        aria-describedby="create-user-form"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Upload Media
          </Typography>
          
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel>Upload Media File</FormLabel>
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: 'block', marginBottom: '10px' }}
              />
              {errors.fileurl && (
                <Typography color="error" variant="body2">
                  {errors.fileurl}
                </Typography>
              )}
            </FormControl>
          </Grid>
          
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleUploadMedia}
          >
            Upload
          </Button>
        </Box>
      </Modal>


      <Modal
        open={openNutritionModal}
        onClose={handleCloseNutritionModal}
        aria-labelledby="create-user-modal"
        aria-describedby="create-user-form"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Nutrition Details
          </Typography>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="nutrition-type-label">Nutrition Type</InputLabel>
            <Select
              labelId="nutrition-type-label"
              value={nutritionType} 
              onChange={handleNutritionTypeChange}
            >
              <MenuItem value="calories">Calories</MenuItem>
              <MenuItem value="protein">Protein</MenuItem>
              <MenuItem value="carbohydrates">Carbohydrates</MenuItem>
              <MenuItem value="fats">Fats</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Nutrition Value"
            variant="outlined"
            value={nutritionValue} 
            onChange={handleNutritionValueChange} 
            sx={{ mb: 2 }} 
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="nutrition-unit-label">Unit</InputLabel>
            <Select
              labelId="nutrition-unit-label"
              value={nutritionUnit} 
              onChange={handleNutritionUnitChange}
            >
              <MenuItem value="g">Grams (g)</MenuItem>
              <MenuItem value="mg">Milligrams (mg)</MenuItem>
              <MenuItem value="µg">Micrograms (µg)</MenuItem>
              <MenuItem value="cal">Calories (cal)</MenuItem>
              <MenuItem value="kcal">Kilocalories (kcal)</MenuItem>
              <MenuItem value="ml">Milliliters (ml)</MenuItem>
              <MenuItem value="cc">Cubic Centimeters (cc)</MenuItem>
              <MenuItem value="%">Percentage (%)</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleNutritionAdd} 
          >
            Add
          </Button>
        </Box>
      </Modal>

      <Modal
        open={openRecipeEditModal}
        onClose={handleCloseRecipeEditModal}
        aria-labelledby="recipe_edit-modal"
        aria-describedby="recipe-edit-form"
      >
        <RecipeUpdateForm recipeId={recipeId} />
      </Modal>

    </Paper>
  );
};

export default Recipe;
