import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Admin from './views/admin/admin';
import Site from './views/site/site';
import Add_Recipe from './views/admin/add_recipe';
import Add_Category from './views/admin/add_category';
import Update_Category from './views/admin/update_category';
import Category from './views/admin/category';
import Slider from './views/admin/slider';
import Add_Slider from './views/admin/add_slider';
import Update_Slider from './views/admin/update_slider';
import Event from './views/admin/event';
import Add_Event from './views/admin/add_event';
import Update_Event from './views/admin/update_event';
import Manage_Recipe  from './views/admin/recipe';
import Advertisment from './views/admin/advertisment';
import Reviews from './views/admin/reviews';
import Settings from './views/admin/setting';
import Users from './views/admin/users';
import Login from './views/admin/login';  // Import Login Component
import PrivateRoute from './components/PrivateRoute'; // Import PrivateRoute Component
import Dashboard from './views/admin/dashboard';
import CookieConsent from 'react-cookie-consent';


function App() {
  return (
    <Router>
      <Routes>
        {/* User routes */}
        <Route path="/*" element={<Site />} />

        {/* Admin login route */}
        <Route path="/login" element={<Login />} />

        {/* Protected Admin routes */}
        <Route path="/admin" element={
          <PrivateRoute>
            <Admin />
          </PrivateRoute>
        }>
          <Route index element={<Dashboard />} />
          <Route path="category" element={<Category />} />
          <Route path="category/create" element={<Add_Category />} />
          <Route path="category/:id" element={<Update_Category />} />
          <Route path="slider" element={<Slider />} />
          <Route path="slider/create" element={<Add_Slider />} />
          <Route path="slider/:id" element={<Update_Slider />} />
          <Route path="event" element={<Event />} />
          <Route path="event/create" element={<Add_Event />} />
          <Route path="event/:id" element={<Update_Event />} />
          <Route path="users" element={<Users />} />
          <Route path="recipes" element={<Manage_Recipe />} />
          <Route path="recipes/create" element={<Add_Recipe />} />
          <Route path="ads" element={<Advertisment />} />
          <Route path="reviews" element={<Reviews />} />
          <Route path="settings" element={<Settings />} />
        </Route>
      </Routes>

      {/* Cookie Consent Popup */}
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="userCookieConsent"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          background: "#4e8d7c",
          color: "#fff",
          fontSize: "13px",
          fontWeight: "bold",
        }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
        <a href="/cookie-policy" style={{ color: "#fff", textDecoration: "underline" }}>Learn More</a>
      </CookieConsent>
    </Router>
  );
}

export default App;
