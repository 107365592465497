import React from 'react';
import { useParams } from 'react-router-dom';
import style from './assets/css/admin.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import UpdateEventForm from './components/updateEventForm';

const Update_Event = () => {
  const { id } = useParams();

  return (
    <div className="mt-4 mx-auto">
      <UpdateEventForm eventId={id} />
    </div>
  );
};

export default Update_Event;
