import React, { useState, useEffect } from 'react';
import { Box, Grid, TextField, Button, Typography, IconButton, Avatar, Card, CardContent } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import axios from 'axios';
import style from '../../assets/css/admin.module.css';
import { styled } from '@mui/system'; 

const RadioGroup = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '8px 0',
});

const Settings_General = () => {
    const defaultImage = 'https://via.placeholder.com/100';  
    const [logoPreview, setLogoPreview] = useState(defaultImage);
    const [faviconPreview, setFaviconPreview] = useState(defaultImage);
    const [settings, setSettings] = useState({
        site_title: '',
        tagline: '',
        description: '',
        footer_text: '',
        date_format: '',
        time_format: '',
        site_logo: defaultImage,
        site_favicon: defaultImage
    });

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/backend/api/setting`)
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setSettings(data.data);
                if (data.data.site_logo) setLogoPreview(data.data.site_logo);
                if (data.data.site_favicon) setFaviconPreview(data.data.site_favicon);
            }
        })
        .catch(error => console.error('Error fetching settings:', error));
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value,
        }));
    };

    const handleImageUpload = (e, key, setPreview) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => setPreview(reader.result);
            reader.readAsDataURL(file);
            setSettings((prevSettings) => ({
                ...prevSettings,
                [key]: file,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        
        Object.keys(settings).forEach((key) => {
            if (key === 'site_logo' || key === 'site_favicon') {
                if (settings[key] !== defaultImage) {
                    formData.append(key, settings[key]);
                }
            } else {
                formData.append(key, settings[key]);
            }
        });

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/backend/api/setting`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            for (let pair of formData.entries()) {
                console.log(pair[0], pair[1]);
            }
            
            if (response.data.success) {
                alert('Settings updated successfully');
            } else {
                alert('Failed to update settings: ' + response.data.message);
            }
        } catch (error) {
            console.error('Error updating settings:', error);
            alert('Error updating settings: ' + error.message);
        }
    };

    return (
        <Box sx={{ padding: 3, backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
            <Card sx={{ boxShadow: 3 }}>
                <CardContent>
                    <Typography variant="h4" gutterBottom>
                        General Settings
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Site Title"
                                    name="site_title"
                                    variant="outlined"
                                    value={settings.site_title || ''}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    label="Tagline"
                                    name="tagline"
                                    multiline
                                    rows={3}
                                    variant="outlined"
                                    value={settings.tagline || ''}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    label="Description"
                                    name="description"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    value={settings.description || ''}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    label="Footer Text"
                                    name="footer_text"
                                    variant="outlined"
                                    value={settings.footer_text || ''}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography variant="h6" sx={{ mt: 3 }}>Site Logo</Typography>
                                <Avatar
                                    alt="Logo Preview"
                                    src={logoPreview}
                                    sx={{ width: 80, height: 80, mb: 2, borderRadius: '10%' }}
                                />
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="logo-upload"
                                    type="file"
                                    onChange={(e) => handleImageUpload(e, 'site_logo', setLogoPreview)}
                                />
                                <label htmlFor="logo-upload">
                                    <IconButton component="span">
                                        <PhotoCamera />
                                    </IconButton>
                                </label>

                                <Typography variant="h6" sx={{ mt: 3 }}>Site Favicon</Typography>
                                <Avatar
                                    alt="Favicon Preview"
                                    src={faviconPreview}
                                    sx={{ width: 80, height: 80, mb: 2, borderRadius: '10%' }}
                                />
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="favicon-upload"
                                    type="file"
                                    onChange={(e) => handleImageUpload(e, 'site_favicon', setFaviconPreview)}
                                />
                                <label htmlFor="favicon-upload">
                                    <IconButton component="span">
                                        <PhotoCamera />
                                    </IconButton>
                                </label>
                            </Grid>
                        </Grid>
                        <Button variant="contained" color="primary" type="submit" sx={{ mt: 4 }}>
                            Update Settings
                        </Button>
                    </form>
                </CardContent>
            </Card>
        </Box>
    );
};

export default Settings_General;
