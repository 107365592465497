import React, { useState } from 'react';
import style from './assets/css/admin.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import SliderForm from './components/sliderForm';

const Add_Slider = () => {


  return (
    <div className='mt-4 mx-auto'>
      <SliderForm />
    </div>
  );
};

export default Add_Slider;
