import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Grid, TextField, Button, Typography, Card, CardContent } from '@mui/material';

const Settings_GoogleAnalytics = () => {
    const [analyticsDetails, setAnalyticsDetails] = useState({
        ga_traking_id: '',
        ga_client_secret: '',
        ga_client_id: '',
        ga_view_id: ''
    });

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/backend/api/setting`)
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setAnalyticsDetails(data.data);
            }
        })
        .catch(error => console.error('Error fetching settings:', error));
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAnalyticsDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        
        Object.keys(analyticsDetails).forEach((key) => {
            formData.append(key, analyticsDetails[key]);
        });

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/backend/api/setting`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            for (let pair of formData.entries()) {
                console.log(pair[0], pair[1]);
            }
            
            if (response.data.success) {
                alert('Settings updated successfully');
            } else {
                alert('Failed to update settings: ' + response.data.message);
            }
        } catch (error) {
            console.error('Error updating settings:', error);
            alert('Error updating settings: ' + error.message);
        }
    };

    return (
        <Box sx={{ padding: 3, backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
            <Card sx={{ boxShadow: 3 }}>
                <CardContent>
                    <Typography variant="h4" gutterBottom>
                        Google Analytics API Details
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Tracking ID"
                                    name="ga_traking_id"
                                    variant="outlined"
                                    value={analyticsDetails.ga_traking_id}
                                    onChange={handleChange}
                                    margin="normal"
                                    required
                                />
                                <TextField
                                    fullWidth
                                    label="Client ID"
                                    name="ga_client_id"
                                    variant="outlined"
                                    value={analyticsDetails.ga_client_id}
                                    onChange={handleChange}
                                    margin="normal"
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Client Secret"
                                    name="ga_client_secret"
                                    variant="outlined"
                                    value={analyticsDetails.ga_client_secret}
                                    onChange={handleChange}
                                    margin="normal"
                                    required
                                />
                                <TextField
                                    fullWidth
                                    label="View ID"
                                    name="ga_view_id"
                                    variant="outlined"
                                    value={analyticsDetails.ga_view_id}
                                    onChange={handleChange}
                                    margin="normal"
                                    required
                                />
                            </Grid>
                        </Grid>

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 4, ':hover': { boxShadow: 4 } }}
                        >
                            Save Changes
                        </Button>
                    </form>
                </CardContent>
            </Card>
        </Box>
    );
}

export default Settings_GoogleAnalytics;
