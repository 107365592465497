// src/views/site/Site.js
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Footer from './footer';
import Categories from './categories';
import Navbar  from './navbar';
import styles from './assets/css/style.module.css';
const Home = lazy(() => import('./home'));
const Contact = lazy(() => import('./contact'));
const About = lazy(() => import('./about'));
const RecipeView = lazy(() => import('./recipe'));
const Categories_View = lazy(() => import('./categories_view'));
const PrivacyPolicy = lazy(() => import('./privacy_policy'));
const TermsCondition = lazy(() => import('./termcondition'));

function Site() {
  const [setting, setSettings] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/backend/api/setting`)
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            setSettings(data.data);
        }
    })
    .catch(error => console.error('Error fetching settings:', error));
}, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="App">
        <Navbar setting={setting}/>
        <div className={styles.main}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/term-condition" element={<TermsCondition />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/category/:slug" element={<Categories_View />} />
            <Route path="/recipe/:permalink" element={<RecipeView />} />
          </Routes>
        </div>
        <Footer setting={setting}/>
      </div>
    </Suspense>
  );
}

export default Site;
