import React, { Suspense } from 'react';

const FaHamburger = React.lazy(() => import('./icon/FaHamburger.js'));
const FaBirthdayCake = React.lazy(() => import('./icon/FaBirthdayCake.js'));
const FaIceCream = React.lazy(() => import('./icon/FaIceCream.js'));
const FaCocktail = React.lazy(() => import('./icon/FaCocktail.js'));
const PiBowlSteamFill = React.lazy(() => import('./icon/PiBowlSteamFill.js'));
const BsCupHotFill = React.lazy(() => import('./icon/BsCupHotFill.js'));
const BiSolidDish = React.lazy(() => import('./icon/BiSolidDish.js'));
const RiDrinksFill = React.lazy(() => import('./icon/RiDrinksFill.js'));
const RiCake3Fill = React.lazy(() => import('./icon/RiCake3Fill.js'));
const FaPizzaSlice = React.lazy(() => import('./icon/FaPizzaSlice.js'));
const FaBowlRice = React.lazy(() => import('./icon/FaBowlRice.js'));
const FaCookieBite = React.lazy(() => import('./icon/FaCookieBite.js'));
const FaBreadSlice = React.lazy(() => import('./icon/FaBreadSlice.js'));
const GiRoastChicken = React.lazy(() => import('./icon/GiRoastChicken.js'));
const GiFrenchFries = React.lazy(() => import('./icon/GiFrenchFries.js'));
const GiBowlOfRice = React.lazy(() => import('./icon/GiBowlOfRice.js'));
const GiNoodles = React.lazy(() => import('./icon/GiNoodles.js'));
const GiFruitBowl = React.lazy(() => import('./icon/GiFruitBowl.js'));
const GiFriedEggs = React.lazy(() => import('./icon/GiFriedEggs.js'));

export const getIconById = (id) => {
  switch (id) {
    case 1:
      return <Suspense fallback={<div style={{ width: '24px', height: '24px' }}>Loading...</div>}>
                <FaHamburger />
              </Suspense>;
    case 2:
      return <Suspense fallback={<div style={{ width: '24px', height: '24px' }}>Loading...</div>}>
                <FaPizzaSlice />
              </Suspense>;
    case 3:
      return <Suspense fallback={<div style={{ width: '24px', height: '24px' }}>Loading...</div>}>
                <FaBowlRice />
              </Suspense>;
    case 4:
      return <Suspense fallback={<div style={{ width: '24px', height: '24px' }}>Loading...</div>}>
                <GiRoastChicken />
              </Suspense>;
    case 5:
      return <Suspense fallback={<div style={{ width: '24px', height: '24px' }}>Loading...</div>}>
                <GiFrenchFries />
              </Suspense>;
    case 6:
      return <Suspense fallback={<div style={{ width: '24px', height: '24px' }}>Loading...</div>}>
                <GiBowlOfRice />
              </Suspense>;
    case 7:
      return <Suspense fallback={<div style={{ width: '24px', height: '24px' }}>Loading...</div>}>
                <FaBowlRice />
              </Suspense>;
    case 8:
      return <Suspense fallback={<div style={{ width: '24px', height: '24px' }}>Loading...</div>}>
                <GiNoodles />
              </Suspense>;
    case 9:
      return <Suspense fallback={<div style={{ width: '24px', height: '24px' }}>Loading...</div>}>
                <RiDrinksFill />
              </Suspense>;
    case 10:
      return <Suspense fallback={<div style={{ width: '24px', height: '24px' }}>Loading...</div>}>
                <PiBowlSteamFill />
              </Suspense>;
    case 11:
      return <Suspense fallback={<div style={{ width: '24px', height: '24px' }}>Loading...</div>}>
                <BsCupHotFill />
              </Suspense>;
    case 12:
      return <Suspense fallback={<div style={{ width: '24px', height: '24px' }}>Loading...</div>}>
                <GiFruitBowl />
              </Suspense>;
    case 13:
      return <Suspense fallback={<div style={{ width: '24px', height: '24px' }}>Loading...</div>}>
                <FaBirthdayCake />
              </Suspense>;
    case 14:
      return <Suspense fallback={<div style={{ width: '24px', height: '24px' }}>Loading...</div>}>
                <RiCake3Fill />
              </Suspense>;
    case 15:
      return <Suspense fallback={<div style={{ width: '24px', height: '24px' }}>Loading...</div>}>
                <FaIceCream />
              </Suspense>;
    case 16:
      return <Suspense fallback={<div style={{ width: '24px', height: '24px' }}>Loading...</div>}>
                <FaCocktail />
              </Suspense>;
    case 17:
      return <Suspense fallback={<div style={{ width: '24px', height: '24px' }}>Loading...</div>}>
                <BiSolidDish />
              </Suspense>;
    case 18:
      return <Suspense fallback={<div style={{ width: '24px', height: '24px' }}>Loading...</div>}>
                <FaCookieBite />
              </Suspense>;
    case 19:
      return <Suspense fallback={<div style={{ width: '24px', height: '24px' }}>Loading...</div>}>
                <GiFriedEggs />
              </Suspense>;
    case 20:
      return <Suspense fallback={<div style={{ width: '24px', height: '24px' }}>Loading...</div>}>
                <FaBreadSlice />
              </Suspense>;
    default:
      return null;
  }
};