import React, { useState } from 'react';
import style from './assets/css/admin.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import CategoryForm from './components/categoryForm';

const Add_Category = () => {


  return (
    <div className='mt-4 mx-auto'>
      <CategoryForm />
    </div>
  );
};

export default Add_Category;
