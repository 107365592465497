import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Collapse,
  Box,
  Popover,
  Grid,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from 'axios';
import Swal from 'sweetalert2';
import style from './assets/css/admin.module.css';

const ITEM_HEIGHT = 48;
const options = ['View', 'Edit', 'Delete'];

const Slider = () => {
  const [records, setRecords] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openCollapse, setOpenCollapse] = useState({});
  const [popoverAnchor, setPopoverAnchor] = useState(null); 
  const [selectedImage, setSelectedImage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/backend/api/sliders`)
      .then((response) => {
        setRecords(response.data);
      })
      .catch((error) => {
        Swal.fire('Error', 'Error fetching data', 'error');
      });
  };

  const deleteCategory = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this category?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${process.env.REACT_APP_API_URL}/backend/api/sliders/${id}`)
          .then(() => {
            Swal.fire('Deleted!', 'Slider has been deleted.', 'success');
            fetchCategories();
          })
          .catch((error) => {
            Swal.fire('Error', 'Error deleting slider', 'error');
          });
      }
    });
  };

  const handleMenuClick = (option, row) => {
    setAnchorEl(null);
    if (option === 'View') {
      Swal.fire('View Category', `Viewing category ID: ${row.id}`, 'info');
    } else if (option === 'Edit') {
      navigate(`/admin/slider/${row.id}`);
    } else if (option === 'Delete') {
      deleteCategory(row.id);
    }
  };

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleImageClick = (event, imageUrl) => {
    setPopoverAnchor(event.currentTarget);
    setSelectedImage(imageUrl);
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
  };

  return (
    <Paper sx={{ p: 3 }} className={style.bg_light}>
      
      <Grid container spacing={2}>
        <Grid item xs={6}>
            <h3>Manage Slider</h3>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Link to="create">Add New</Link>
        </Grid>
      </Grid>
      <Divider orientation="horizontal" variant="middle" flexItem />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Recipe</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Desktop Image</TableCell>
              <TableCell>Tablet Image</TableCell>
              <TableCell>Mobile Image</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Updated</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <React.Fragment key={row.id}>
                <TableRow
                    style={{
                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white', // Alternating row colors
                    }}
                >
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.recipe.title}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>
                    <img
                        src={`${process.env.REACT_APP_IMAGE_PATH_URL}/${row.desktop_image}`}
                        alt={row.title}
                        style={{ maxWidth: '100%', maxHeight: '60px', cursor: 'pointer' }}
                        onClick={(event) => handleImageClick(event, `${process.env.REACT_APP_IMAGE_PATH_URL}/${row.desktop_image}`)}
                    />
                    </TableCell>
                    <TableCell>
                    <img
                        src={`${process.env.REACT_APP_IMAGE_PATH_URL}/${row.tablet_image}`}
                        alt={row.title}
                        style={{ maxWidth: '100%', maxHeight: '60px', cursor: 'pointer' }}
                        onClick={(event) => handleImageClick(event, `${process.env.REACT_APP_IMAGE_PATH_URL}/${row.tablet_image}`)}
                    />
                    </TableCell>
                    <TableCell>
                    <img
                        src={`${process.env.REACT_APP_IMAGE_PATH_URL}/${row.mobile_image}`}
                        alt={row.title}
                        style={{ maxWidth: '100%', maxHeight: '60px', cursor: 'pointer' }}
                        onClick={(event) => handleImageClick(event, `${process.env.REACT_APP_IMAGE_PATH_URL}/${row.mobile_image}`)}
                    />
                    </TableCell>
                    <TableCell>{new Date(row.createdAt).toLocaleString()}</TableCell>
                    <TableCell>{new Date(row.updatedAt).toLocaleString()}</TableCell>
                    <TableCell>
                    <IconButton
                        aria-label="more"
                        aria-controls={anchorEl ? 'long-menu' : undefined}
                        aria-haspopup="true"
                        onClick={(event) => handleClick(event, row)}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl && selectedRow === row)}
                        onClose={handleClose}
                        PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                        },
                        }}
                    >
                        {options.map((option) => (
                        <MenuItem
                            key={option}
                            onClick={() => handleMenuClick(option, row)}
                        >
                            {option}
                        </MenuItem>
                        ))}
                    </Menu>
                    </TableCell>
                </TableRow>
                </React.Fragment>
            ))}
            </TableBody>

        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={records.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Popover
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <img
          src={selectedImage}
          alt="Enlarged category"
          style={{ maxWidth: '500px', maxHeight: '500px' }}
        />
      </Popover>
    </Paper>
  );
};

export default Slider;
