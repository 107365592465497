import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import styles from './assets/css/style.module.css';
import ArrowIcon from '@mui/icons-material/ArrowCircleRight';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';


const Footer = ({setting}) => {
    const settingRef = useRef();
    const [error, setError] = useState('');
  
  
    if (error) return <p>{error}</p>;
    if (!setting) return <p></p>;

    return (
        <div ref={settingRef} className={styles.footer}>
            <div className="row p-2">
                <div className="col-lg-6 col-md-4 col-sm-12 position-relative">
                    <div className={styles.website_details}>
                      <Link to="/">
                        <img
                            className="img-fluid"
                            src={`${process.env.REACT_APP_IMAGE_PATH_URL}/${setting.site_logo}`}
                            width={300}
                            />
                      </Link>
                      <p>{setting.description}</p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-12">
                    <div className="p-2">
                        <h5 className="fw-bolder">Most Viewed</h5>
                        <ul>
                            <li><a href="#"><ArrowIcon className={styles.icon} />Link 1</a></li>
                            <li><a href="#"><ArrowIcon className={styles.icon} />Link 2</a></li>
                            <li><a href="#"><ArrowIcon className={styles.icon} />Link 3</a></li>
                            <li><a href="#"><ArrowIcon className={styles.icon} />Link 4</a></li>
                            <li><a href="#"><ArrowIcon className={styles.icon} />Link 5</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-12">
                    <div className="p-2">
                        <h5 className="fw-bolder">Useful Links</h5>
                        <ul>
                            <li>
                             <Link to="/about">
                                <ArrowIcon className={styles.icon} />About Us
                             </Link>
                            </li>
                            <li>
                             <Link to="/contact">
                                <ArrowIcon className={styles.icon} />Contact Us
                             </Link>
                            </li>
                            <li>
                             <Link to="/privacy-policy">
                                <ArrowIcon className={styles.icon} />Privacy Policy
                             </Link>
                            </li>
                            <li>
                             <Link to="/term-condition">
                                <ArrowIcon className={styles.icon} />Terms & Conditions
                             </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="border-top p-2">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                       <p className=" text-center">&copy; {setting.footer_text}</p>
                    </div>
                    <div className="col-md-6 col-sm-12 text-center">
                        <ul className={`list-inline ${styles.social_icon}`}>
                            <li className={`list-inline-item px-2 ${styles.facebookicon}`}>
                                <a href="#">
                                   <FacebookIcon />
                                </a>
                            </li>
                            <li className={`list-inline-item px-2 ${styles.twittericon}`}>
                                <a href="#">
                                   <TwitterIcon />
                                </a>
                            </li>
                            <li className={`list-inline-item px-2 ${styles.instaicon}`}>
                                <a href="#">
                                <InstagramIcon />
                                </a>
                            </li>
                            <li className={`list-inline-item px-2 ${styles.facebookicon}`}>
                                <a href="#">
                                   <ArrowIcon className={styles.icon} />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;