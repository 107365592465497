// src/components/IconList.js
import React, { Suspense } from 'react';

const FaHamburger = React.lazy(() => import('./icon/FaHamburger.js'));
const FaBirthdayCake = React.lazy(() => import('./icon/FaBirthdayCake.js'));
const FaIceCream = React.lazy(() => import('./icon/FaIceCream.js'));
const FaCocktail = React.lazy(() => import('./icon/FaCocktail.js'));
const PiBowlSteamFill = React.lazy(() => import('./icon/PiBowlSteamFill.js'));
const BsCupHotFill = React.lazy(() => import('./icon/BsCupHotFill.js'));
const BiSolidDish = React.lazy(() => import('./icon/BiSolidDish.js'));
const RiDrinksFill = React.lazy(() => import('./icon/RiDrinksFill.js'));
const RiCake3Fill = React.lazy(() => import('./icon/RiCake3Fill.js'));
const FaPizzaSlice = React.lazy(() => import('./icon/FaPizzaSlice.js'));
const FaBowlRice = React.lazy(() => import('./icon/FaBowlRice.js'));
const FaCookieBite = React.lazy(() => import('./icon/FaCookieBite.js'));
const FaBreadSlice = React.lazy(() => import('./icon/FaBreadSlice.js'));
const GiRoastChicken = React.lazy(() => import('./icon/GiRoastChicken.js'));
const GiFrenchFries = React.lazy(() => import('./icon/GiFrenchFries.js'));
const GiBowlOfRice = React.lazy(() => import('./icon/GiBowlOfRice.js'));
const GiNoodles = React.lazy(() => import('./icon/GiNoodles.js'));
const GiFruitBowl = React.lazy(() => import('./icon/GiFruitBowl.js'));
const GiFriedEggs = React.lazy(() => import('./icon/GiFriedEggs.js'));

const icons = [
  { id: 1, name: 'Burger', icon: <FaHamburger /> },
  { id: 2, name: 'Pizza', icon: <FaPizzaSlice /> },
  { id: 3, name: 'Rice', icon: <FaBowlRice /> },
  { id: 4, name: 'Chicken', icon: <GiRoastChicken /> },
  { id: 5, name: 'Fries', icon: <GiFrenchFries /> },
  { id: 6, name: 'Rice V2', icon: <GiBowlOfRice /> },
  { id: 7, name: 'Rice V3', icon: <FaBowlRice /> },
  { id: 8, name: 'Noodles', icon: <GiNoodles /> },
  { id: 9, name: 'Drinks', icon: <RiDrinksFill /> },
  { id: 10, name: 'Bowl', icon: <PiBowlSteamFill /> },
  { id: 11, name: 'Hot Cup', icon: <BsCupHotFill /> },
  { id: 12, name: 'Fruit Bowl', icon: <GiFruitBowl /> },
  { id: 13, name: 'Cake', icon: <FaBirthdayCake /> },
  { id: 14, name: 'Cup Cake', icon: <RiCake3Fill /> },
  { id: 15, name: 'Ice Cream', icon: <FaIceCream /> },
  { id: 16, name: 'CookTail', icon: <FaCocktail /> },
  { id: 17, name: 'Dish', icon: <BiSolidDish /> },
  { id: 18, name: 'Cookie', icon: <FaCookieBite /> },
  { id: 19, name: 'Eggs', icon: <GiFriedEggs /> },
  { id: 20, name: 'Bread Slice', icon: <FaBreadSlice /> },
  
];

const IconList = ({ onIconSelect }) => {
  return (
    <div>
      {icons.map((iconItem) => (
        <div key={iconItem.id} style={{ margin: '10px', textAlign: 'center' }}>
          <label style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="radio"
              name="icon"
              value={iconItem.id}
              onChange={() => onIconSelect(iconItem.id)}
              style={{ marginRight: '5px' }}
            />
            {/* Use Suspense to handle the loading state for each icon */}
            <Suspense fallback={<div style={{ width: '24px', height: '24px' }} />}>
              <div style={{ width: '24px', height: '24px' }}>{iconItem.icon}</div>
            </Suspense>
            <div style={{ marginLeft: '5px' }}>{iconItem.name}</div>
          </label>
        </div>
      ))}
    </div>
  );
};

export default IconList;