import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './assets/css/style.module.css';

const Categories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/backend/api/categories/countRecipesByCategory`)
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });
  };

  return (
    <div className={`container-fluid ${styles.content}`}>
      <div className={styles.top_banner}>
        <div className={styles.banner_content}>
          <h3>Categories</h3>
          <p>Permalink</p>
        </div>
      </div>
      <div className={`container my-4 ${styles.categories_page}`}>
        <div className='row'>
          {categories.map((category) => {
            return (
              <div key={category.id} className='col-lg-3 col-md-4 col-sm-12 my-2'>
                <div className={styles.categories_box}>
                <Link to={`/category/${category.permalink}`}>
                    <div className={styles.categories_image}>
                      <img
                        className="img-fluid rounded"
                        src={`${process.env.REACT_APP_IMAGE_PATH_URL}/${category.image}`}
                        alt={category.title}
                        width="100%"
                        height="150px"
                      />
                    </div>
                    <div className={styles.categories_content}>
                      <h5>{category.title}</h5>
                      <p>{category.recipe_count} Recipe{category.recipe_count !== 1 ? 's' : ''}</p>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Categories;
