import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { AppBar, Box, CssBaseline, Drawer, List, ListItem, ListItemText, Toolbar, Typography, IconButton, Collapse, Divider, ListSubheader } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DashboardIcon from '@mui/icons-material/Dashboard';
import UserIcon from '@mui/icons-material/PeopleAlt';
import SlideIcon from '@mui/icons-material/Slideshow';
import SettingsIcon from '@mui/icons-material/Settings';
import CategoryIcon from '@mui/icons-material/Category';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const drawerWidth = 240;

const Admin = () => {
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 800) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/admin' },
    { text: 'Users', icon: <UserIcon />, path: '/admin/users' },
    { text: 'Slider', icon: <SlideIcon />, path: '/admin/slider' },
    { text: 'Category', icon: <CategoryIcon />, path: '/admin/category' },
    { text: 'Event', icon: <ManageAccountsIcon />, path: '/admin/event' },
    { text: 'Recipes', icon: <ManageAccountsIcon />, path: '/admin/recipes' },
    { text: 'Advertisment', icon: <ManageAccountsIcon />, path: '/admin/ads' },
    { text: 'Settings', icon: <SettingsIcon />, path: '/admin/settings' },
  ];

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      
      <Drawer
        sx={{
          width: isSidebarOpen ? drawerWidth : 60,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: isSidebarOpen ? drawerWidth : 60,
            boxSizing: 'border-box',
            transition: 'width 0.3s ease',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <List sx={{ paddingTop: 0 }}>
          <ListSubheader sx={{ paddingLeft: 2, paddingTop: 1, opacity: isSidebarOpen ? 1 : 0, transition: 'opacity 0.3s ease' }}>
            Main items
          </ListSubheader>
          {menuItems.map(({ text, icon, path }) => (
            <ListItem
              button
              key={text}
              component={Link}
              to={path}
              sx={{
                color: 'gray',
                '&:hover': {
                  color: 'blue',
                },
                backgroundColor: location.pathname === path ? '#e0e0e0' : 'transparent', // Active background color
                '&:hover': {
                  backgroundColor: location.pathname === path ? '#e0e0e0' : '#f0f0f0',
                },
                transition: 'background-color 0.3s ease',
              }}
            >
              {icon}
              <Collapse in={isSidebarOpen} timeout="auto" unmountOnExit>
                <ListItemText primary={text} sx={{ ml: 2 }} />
              </Collapse>
            </ListItem>
          ))}
          <Divider sx={{ my: 2 }} />
        </List>
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <IconButton onClick={toggleSidebar} sx={{ mr: 2 }}>
              {isSidebarOpen ? <ChevronLeftIcon /> : <MenuIcon />}
            </IconButton>
            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
              Admin Header
            </Typography>
          </Toolbar>
        </AppBar>

        <Toolbar />
        <Box sx={{ pt: 2 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Admin;
