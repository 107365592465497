import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './assets/css/style.module.css';

const Navbar = ({setting}) => {
  const settingRef = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [error, setError] = useState('');


  if (error) return <p>{error}</p>;
  if (!setting) return <p></p>;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };



  return (
    <header ref={settingRef} className={styles.header}>
      <div className="container">
        <a href="index.html" className={styles.logo}>
        <img
            className="img-fluid"
            src={`${process.env.REACT_APP_IMAGE_PATH_URL}/${setting.site_logo}`}
            width="220"
            height="auto"
            loading="lazy"
            alt="Site Logo"
          />

        </a>
        <input 
          className={styles.menu_btn} 
          type="checkbox" 
          id="menu-btn" 
          checked={isMenuOpen} 
          onChange={toggleMenu} 
        />
        <label className={styles.menu_icon} htmlFor="menu-btn">
          <span className={styles.navicon}></span>
        </label>
        <ul className={`${styles.menu} ${isMenuOpen ? styles.open : ''}`}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className={styles.dropdown} onClick={toggleDropdown}>
            <Link to="#">Cuisine</Link>
            <ul className={`${styles.dropdownMenu} ${isDropdownOpen ? styles.showDropdown : ''}`}>
              <li>
                <Link to="/lunch">Lunch</Link>
              </li>
              <li>
                <Link to="/dinner">Dinner</Link>
              </li>
              <li>
                <Link to="/meal">Meal</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/occasions">Occasions</Link>
          </li>
          <li>
            <Link to="/about">About Us</Link>
          </li>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
          <li className={styles.newsletter}>
            <button type='button' className={styles.newsletterBtn}>Newsletter</button>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Navbar;
