import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Menu, MenuItem, IconButton, Typography,
  Modal, Box, TextField, Grid, Select, MenuItem as MuiMenuItem, InputLabel, FormControl
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import style from './assets/css/admin.module.css';
import Swal from 'sweetalert2';

const Advertisment = () => {
  const [users, setUsers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [newUser, setNewUser] = useState({
    username: '',
    email: '',
    password: '',
    role: '',
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/backend/api/users`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        Swal.fire('Error', 'Error fetching data', 'error');
      });
  };

  const handleActionClick = (event, user) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedUser(null);
  };

  const handleDelete = (userId) => {
    console.log('Delete user:', userId);
    handleCloseMenu();
  };

  const handleEdit = (userId) => {
    console.log('Edit user:', userId);
    handleCloseMenu();
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleCreateUser = () => {
    console.log('New user details:', newUser);
    // Perform user creation logic with newUser object
    // axios.post('/api/users', newUser) // Uncomment and modify the API call accordingly
    handleCloseModal();
  };

  return (
    <Paper sx={{ p: 3 }} className={style.bg_light}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
             <h3>Manage Ads</h3>
          </Grid>
          <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                sx={{ mb: 2 }}
                onClick={handleOpenModal}
              >
                Create New Event
            </Button>
          </Grid>
        </Grid>
      <TableContainer component={Paper} sx={{ mt: 4, p: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="user table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={(event) => handleActionClick(event, user)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && selectedUser?.id === user.id}
                    onClose={handleCloseMenu}
                  >
                    <MenuItem onClick={() => handleEdit(user.id)}>Edit</MenuItem>
                    <MenuItem onClick={() => handleDelete(user.id)}>Delete</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for creating a new user */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="create-user-modal"
        aria-describedby="create-user-form"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Create New User
          </Typography>
          <TextField
            label="Username"
            name="username"
            fullWidth
            variant="outlined"
            margin="normal"
            value={newUser.username}
            onChange={handleInputChange}
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            fullWidth
            variant="outlined"
            margin="normal"
            value={newUser.email}
            onChange={handleInputChange}
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            fullWidth
            variant="outlined"
            margin="normal"
            value={newUser.password}
            onChange={handleInputChange}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="role-label">Role</InputLabel>
            <Select
              labelId="role-label"
              name="role"
              value={newUser.role}
              onChange={handleInputChange}
              label="Role"
            >
              <MuiMenuItem value="admin">Admin</MuiMenuItem>
              <MuiMenuItem value="editor">Editor</MuiMenuItem>
              <MuiMenuItem value="viewer">Viewer</MuiMenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleCreateUser}
          >
            Create User
          </Button>
        </Box>
      </Modal>
    </Paper>
  );
};

export default Advertisment;
