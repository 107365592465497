import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';
import PiForkKnifeBold  from "../../components/icons/icon/PiForkKnifeBold";
import FaRegEye from "../../components/icons/icon/FaRegEye";
import FaStarHalfStroke from "../../components/icons/icon/FaStarHalfStroke";
import TbCategory from "../../components/icons/icon/TbCategory";
import PiUsersThreeBold from "../../components/icons/icon/PiUsersThreeBold";
import BiLike from "../../components/icons/icon/BiLike";
import MdOutlineArticle from "../../components/icons/icon/MdOutlineArticle";
import axios from 'axios';
import Swal from 'sweetalert2';



const DashboardBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  padding: theme.spacing(3),
  height: '150px',
  textAlign: 'center',
}));

const Dashboard = () => {
  
  const [records, setRecords] = useState([]);
  useEffect(() => {
    fetchDashboard();
  }, []);

  const fetchDashboard = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/backend/api/count`)
      .then((response) => {
        setRecords(response.data);
      })
      .catch((error) => {
        Swal.fire('Error', 'Error fetching data', 'error');
      });
  };

  return (
    <Box sx={{ mx: 'auto', width: '95%', p:4, background: 'white' }}>
      <Typography variant="h6" component="h6" gutterBottom sx={{ color: 'text.primary' }}>
        Dashboard
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
            <DashboardBox>
                <Box 
                    sx={{ 
                    display: 'inline-flex', 
                    alignItems: 'center', 
                    gap: 1,
                    }}
                >
                    <PiForkKnifeBold style={{ fontSize: '3rem', color: 'black' }} /> 
                    <Typography variant="h4" sx={{color: 'black'}}>{records.recipes}</Typography>
                </Box>
                <Typography variant="h6" sx={{color: 'black'}}>Recipes</Typography>
            </DashboardBox>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <DashboardBox>
               <Box 
                    sx={{ 
                    display: 'inline-flex', 
                    alignItems: 'center', 
                    gap: 1,
                    }}
                >
                    <TbCategory style={{ fontSize: '3rem', color: 'black' }} /> 
                    <Typography variant="h4" sx={{color: 'black'}}>{records.categories}</Typography>
                </Box>
            <Typography variant="h6" sx={{color: 'black'}}>Categories</Typography>
          </DashboardBox>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <DashboardBox>
            <Box 
                sx={{ 
                display: 'inline-flex', 
                alignItems: 'center', 
                gap: 1,
                }}
            >
                <PiForkKnifeBold style={{ fontSize: '3rem', color: 'black' }} /> 
                <Typography variant="h4" sx={{color: 'black'}}>{records.event}</Typography>
            </Box>
            <Typography variant="h6" sx={{color: 'black'}}>Events</Typography>
          </DashboardBox>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <DashboardBox>
            <Box 
                sx={{ 
                display: 'inline-flex', 
                alignItems: 'center', 
                gap: 1,
                }}
            >
                <PiUsersThreeBold style={{ fontSize: '3rem', color: 'black' }} /> 
                <Typography variant="h4" sx={{color: 'black'}}>{records.user}</Typography>
            </Box>
            <Typography variant="h6" sx={{color: 'black'}}>Users</Typography>
          </DashboardBox>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <DashboardBox>
            <Box 
                sx={{ 
                display: 'inline-flex', 
                alignItems: 'center', 
                gap: 1,
                }}
            >
                <FaRegEye style={{ fontSize: '3rem', color: 'black' }} /> 
                <Typography variant="h4" sx={{color: 'black'}}>{records.view}</Typography>
            </Box>
            <Typography variant="h6" sx={{color: 'black'}}>Views</Typography>
          </DashboardBox>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <DashboardBox>
            <Box 
                sx={{ 
                display: 'inline-flex', 
                alignItems: 'center', 
                gap: 1,
                }}
            >
                <BiLike style={{ fontSize: '3rem', color: 'black' }} /> 
                <Typography variant="h4" sx={{color: 'black'}}>{records.review}</Typography>
            </Box>
            <Typography variant="h6" sx={{color: 'black'}}>Reviews</Typography>
          </DashboardBox>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <DashboardBox>
            <Box 
                sx={{ 
                display: 'inline-flex', 
                alignItems: 'center', 
                gap: 1,
                }}
            >
                <FaStarHalfStroke style={{ fontSize: '3rem', color: 'black' }} /> 
                <Typography variant="h4" sx={{color: 'black'}}>{records.averageRating}</Typography>
            </Box>
            <Typography variant="h6" sx={{color: 'black'}}>Average Rating</Typography>
          </DashboardBox>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <DashboardBox>
            <Box 
                sx={{ 
                display: 'inline-flex', 
                alignItems: 'center', 
                gap: 1,
                }}
            >
                <MdOutlineArticle style={{ fontSize: '3rem', color: 'black' }} /> 
                <Typography variant="h4" sx={{color: 'black'}}>---</Typography>
            </Box>
            <Typography variant="h6" sx={{color: 'black'}}>Blogs</Typography>
          </DashboardBox>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
