import React, { useState } from 'react';
import style from './assets/css/admin.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import RecipeForm from './components/recipeForm';

const Add_Recipe = () => {


  return (
    <div className='mt-4 mx-auto'>
      <RecipeForm />

        
    </div>
  );
};

export default Add_Recipe;
